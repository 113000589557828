import CheckV2White from "../../style/icons/CheckV2White";
import React from "react";
import { MarkupToHtml } from "../../helpers/parse";

const CompetitionInfoCard = ({ text }) => {
    return (
        <div className="flex flex-row justify-start items-start gap-6" >
            <CheckV2White height={"18px"} width={"18px"} bg={"#A8BE32"} />
            <span className="text-base leading-6 text-gray-600 m-0"><MarkupToHtml>{text}</MarkupToHtml></span>
        </div>
    )
}

export default CompetitionInfoCard;