import CompetitionInfoCard from "./competitionInfo/CompetitionInfoCard";
import React from "react";
import styled, {css} from "styled-components";

export const CompetitionInfo = ({ contestInfoLeft, contestInfoRight }) => {
    // Zajistíme, že contestInfoLeft a contestInfoRight jsou vždy pole
    const leftRules = Array.isArray(contestInfoLeft) ? contestInfoLeft : [];
    const rightRules = Array.isArray(contestInfoRight) ? contestInfoRight : [];

    return (
        <div className="flex flex-row justify-center items-start flex-wrap lg:gap-20 gap-4 w-full">
            <div className="flex flex-col justify-start items-start gap-5 max-w-[550px]">
               {leftRules.map((rule, index) => (
                <CompetitionInfoCard key={index} text={rule.Rule} />
               ))}
            </div>
            <MyStyledComponent className="flex flex-col justify-start items-start gap-5">
              {rightRules.map((rule, index) => (
                <CompetitionInfoCard key={index} text={rule.Rule} />
              ))}
            </MyStyledComponent>
          </div>
    )
}

const MyStyledComponent = styled.div`
  @media (min-width: 1212px) {
    height: 100%;
    border-left: 1px solid #E6E6E7;
    padding-left: 64px;
    max-width: 614px;
  }

  @media (max-width: 1211px) {
    border-left: none;
    max-width: 550px;
  }
`

