import CheckV2White from "../../style/icons/CheckV2White";
import { Heading } from "../../style/Heading";
import React from "react";

const ContestStep = ({ step }) => {
    return (
        <div className="flex flex-row gap-4 items-center">
              <CheckV2White height={"18px"} width={"18px"} bg={"#2B6E33"} />
              <Heading as="h5" fontSize={["16px", null, "20px", null]} fontWeight="200" color={["green.dark", "white.100"]}>
                {step}
            </Heading>
        </div>
    );
};

export default ContestStep;