import React from "react";

const CheckV2White = ({ width = 51, height = 50, bg }) => {
  // Calculate scale factor based on original SVG dimensions
  const originalWidth = 51;
  const originalHeight = 50;

  // Convert width and height to numbers if they're strings with units (e.g., "24px")
  const numericWidth = typeof width === 'string' ? parseInt(width, 10) : width;
  const numericHeight = typeof height === 'string' ? parseInt(height, 10) : height;
  
  // Ensure consistent aspect ratio
  return (
    <svg 
      width={width} 
      height={height} 
      viewBox="0 0 51 50" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      style={{ minWidth: numericWidth, minHeight: numericHeight }}
    >
      <circle cx="25" cy="25" r="25" fill={bg} />
      <path
        d="M42.3498 17.5324L41.3617 16.5725L37.0703 12.2812L36.0822 11.293L35.094 12.2529L19.0297 28.3455L14.9077 24.28L13.9478 23.2919L12.9596 24.28L8.69652 28.5432L7.70837 29.5313L8.69652 30.4912L18.0415 39.8645L19.0297 40.8244L20.0178 39.8645L41.3334 18.5206L42.3498 17.5324Z"
        fill="#fff"
      />
    </svg>
  );
};

export default CheckV2White;
