import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import MainLayout from "../componentsTailwind/MainLayout";
import { Flex } from "../style/Grid";
import ContactForm from "../components/contactForm";
import { CONTRACT_SOURCE_ID_BIKE_CONTEST } from "../constants";
import { useCalculationResultChange } from "../components/SavingsCalculation/hooks/use-calculation-result-change";
import MainVizualBikeLP from "../components/MainVizualBikeLP";
import BikeContestContactFormWrapper from "../components/LeadForms/BikeContestContactFormWrapper";
import { getFullMediaLibraryURL } from "../helpers/mediaLibrary/mediaLibraryUtils";
import { getSuppliers } from "../api/api";
import {CompetitionInfo} from "../components/CompetitionInfo";
import { MarkupToHtml } from "../helpers/parse";

const SoutezElektrokolaPage = props => {
  const CalculatingResultChange = useCalculationResultChange();
  const [topGasSupplier, setTopGasSupplier] = useState(null);
  const [topElectricitySupplier, setTopElectricitySupplier] = useState(null);
  const [gasSupplier, setGasSupplier] = useState(null);
  const [electricitySupplier, setElectricitySupplier] = useState(null);

  const { data } = props;
  
  // Data z sloučeného dotazu
  const cykloPageData = data?.mnd?.cyklistika?.data?.attributes || {};

  // banner
  const banner = cykloPageData.IntroBanner || {};
  const bannerImageUrl = banner.image?.data?.attributes?.url 
    ? `url(${getFullMediaLibraryURL(banner.image.data.attributes.url)})`
    : '';
  const { title = '', text = '', buttonTitle = '', buttonLink = '' } = banner.bannerContent || {};

  const ContestInfoHeader = cykloPageData.ContestInfoHeader || '';

  // competition form
  const competitionForm = cykloPageData.BikeContestForm || {};
  const { FormTitle = '', CykloFormStep1 = '', CykloFormStep2 = '', CykloFormStep3 = '' } = competitionForm;

  useEffect(() => {
    const fetchSuppliers = async () => {
      const gasSupplier = await getSuppliers("gas", false, true);
      const electricitySupplier = await getSuppliers("electricity", false, true);
      setGasSupplier(gasSupplier.all);
      setElectricitySupplier(electricitySupplier.all);
      setTopGasSupplier(gasSupplier.top);
      setTopElectricitySupplier(electricitySupplier.top);
    };
    fetchSuppliers();
  }, []);

  const contestInfoLeft = props.data.mnd.cyklistika.data.attributes.ContestInfoLeft;
  const contestInfoRight = props.data.mnd.cyklistika.data.attributes.ContestInfoRight;


  const {
    consumption = 0,
    commodity = '',
    consumptionHighTariff = 0
  } = CalculatingResultChange;


  if (!cykloPageData) {
    return null; // or some loading state/error component
  }

  return (
    <MainLayout
      headerConfig={{
        topPanel: true,
        mainPanel: false,
      }}
    >
      <Helmet>
        <title>Soutěž elektrokola | MND</title>
        <link rel="canonical" href="https://www.mnd.cz/soutez-elektrokola"></link>
        <script>{`
          var gaAdditionalPageData = JSON.stringify({ type: 'Landingpage', section: 'Gas | Electricity' });
        `}</script>
      </Helmet>

      <MainVizualBikeLP
        title={title}
        text={text}
        backgroundImage={bannerImageUrl}
        buttonLink={buttonLink}
        buttonTitle={buttonTitle}
        customImage
        customStyle={{
          wrapper: {
            paddingTop: ["284px", null, 0],
            backgroundColor: ["#f1f4db"],
          },
          heading: {
            mb: ["16px", null, "24px"],
          },
          textWrapper: {
            zIndex: 2,
            px: "0px",
            paddingRight: ["0px", null, "22px", "22px"],
            backgroundColor: ["#f1f4db"],
          },
          bannerImage: {
            backgroundPosition: "center bottom",
            backgroundSize: "contain",
            height: "254px",
            mx: "15px",
            marginTop: "30px",
            backgroundColor: ["#f1f4db"],
          },
          boxContainer: {
            minHeight: [null, null, "396px"],
            px: ["20px", null, "15px"],
            backgroundColor: ["#f1f4db"],
          },
          customImage: {
            backgroundPosition: "top center",
            backgroundSize: "cover",
          },
        }}
      />

      <BikeContestContactFormWrapper
        headline={FormTitle}
        cykloSteps={[CykloFormStep1, CykloFormStep2, CykloFormStep3]}
      >
        <ContactForm
          enableCallbackTime={true}
          enableEmail={false}
          type="BIKE_CONTEST"
          commodity={commodity}
          consumption={consumption * 1000}
          consumptionHighTariff={consumptionHighTariff * 1000}
          analytics={{
            type: "Lead",
            text: {buttonTitle},
            section: "Landingpage",
            sourceId: CONTRACT_SOURCE_ID_BIKE_CONTEST,
          }}
          formSteps={{
            CykloFormStep1,
            CykloFormStep2,
            CykloFormStep3,
          }}
          gasSupplier={gasSupplier}
          electricitySupplier={electricitySupplier}
          topGasSupplier={topGasSupplier}
          topElectricitySupplier={topElectricitySupplier}
          leadsource={CONTRACT_SOURCE_ID_BIKE_CONTEST}
        />
      </BikeContestContactFormWrapper>

      <div className={"max-w-screen-l mx-auto px-4 py-10 sm:py-14 h-auto"}>
        <div className="flex-col text-left sm:text-center mb-10">
          <h2 className={"text-H2 sm:text-H3 mb-0 bold text-exploratory-green-400 text-center"}>
            <MarkupToHtml>
              {ContestInfoHeader}
            </MarkupToHtml>
          </h2>
        </div>

        <Flex justifyContent="center" pt={4} width={"100%"}>
          <CompetitionInfo contestInfoLeft={contestInfoLeft} contestInfoRight={contestInfoRight} />
        </Flex>
      </div>
    </MainLayout >
  );
};

export default SoutezElektrokolaPage;

export const pageQuery = graphql`
  query CykloPageAndContestInfo {
    mnd {
      cyklistika {
        data {
          attributes {
            # Intro banner component
            IntroBanner {
              bannerContent {
                title
                text
                buttonTitle
                buttonLink
                footerText
              }
              image {
                data {
                  attributes {
                    url
                  }
                }
              }
            }

            # Competition form
            BikeContestForm {
              FormTitle
              CykloFormStep1
              CykloFormStep2
              CykloFormStep3
            }
            
            # Contest info data
            ContestInfoLeft {
              Rule
            }
            ContestInfoRight {
              Rule
            }
            ContestInfoHeader
          }
        }
      }
    }
  }
`;
