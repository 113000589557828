import React from "react";
import { Container, Flex, Wrapper } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import ContestStep from "./ContestStep";
export const FORM_CONTACT_FORM_WRAPPER_ID = "kolo";

const BikeContestContactFormWrapper = ({ headline = "Získejte výhodné energie od MND!", children, wrapperRef = undefined, cykloSteps = [] }) =>
{
  return (
    <Wrapper
      position={"relative"}
      background={["white.100", `#a7be32 url(${require("../../images/contactForm__bg.jpg").default}) no-repeat bottom center`]}
      p={["30px 0", "60px 0 40px"]}
      id={FORM_CONTACT_FORM_WRAPPER_ID}
      className="scroll-my-[52px]"
      ref={wrapperRef}
    >
      <Container>
        <Flex flexDirection="column" alignItems={["flex-start", "center"]} width={1} mb={[0, 50]}>
          <Heading as="h2" fontSize={["26px", null, "36px", null]} fontWeight="700" color={["green.dark", "white.100"]}>
            {headline}
          </Heading>
          <div className="flex md:flex-row lg:gap-20 gap-4 md:mt-10 mt-4 md:mb-0 mb-5 flex-wrap justify-center items-start md:items-center flex-col">
            <ContestStep step={cykloSteps[0]} />
            <ContestStep step={cykloSteps[1]} />
            <ContestStep step={cykloSteps[2]} />
          </div>
        </Flex>
        {children}
      </Container>
    </Wrapper>
  );
};

export default BikeContestContactFormWrapper;
